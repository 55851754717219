
.gap_0 {
  gap: 12px !important;
}




.line-des {
  height: 0.5 !important;
  width: 60px !important;
  margin-top: -5px;
}

.line-des1 {
  gap: 30px !important;
}

.carousel-indicators .active {
  background-color: white !important;
}

.carousel-indicators button {
  background-color: #BD8833 !important;
  opacity: 1 !important;
}

.p_button {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.last_rentals h2 {
  word-break: break-word;
}

.Massage ul li {
  margin-bottom: 10px;
}

.button_p {
  width: 217px;
}

.group_n .Depending_img p {
  margin-top: 20px;
}

.contact_form p {
  margin-bottom: 0px;
}

.slider_data .slick-slide {
  margin-right: 0px !important;
}

.slider_data .slider_left .slick-list {
  width: 100% !important;
}

.slider_data .slick-slider .slick-track,
.slick-slider .slick-list {
  padding: 0px !important;
}

.slider_data .slick-prev,
.slider_data .slick-next {
  display: none !important;
}


.languagaes_fr .options li {
  cursor: pointer;
  padding: 0px 13px !important;

}

.languagaes_fr .options {
  right: -50px;
}

@media (max-width:1540px) {
  .languagaes_fr .options {
    right: 0px;
  }
 
}

@media (max-width:1280px) {
  .languagaes_fr .options {
    right: -7px;
  }

  .header_menu {
    padding-right: 0.15rem !important;
    padding-left: 0.25rem !important;
    max-width: 216px;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    margin-top: 69px!important;

  }
  .menu_mo {
    margin-left: calc(0rem* calc(1 - var(--tw-space-x-reverse)))!important;
}
}
@media (max-width:1279px) {
  .header_menu {
    margin-top: 27px!important;

  }
}
.button_bok {
  padding: 7px 0px;

}

@media (max-width:1024px) {
  .data_img {
    object-fit: cover;
  }

  .line-des {
    height: 0.5 !important;
    width: 40px !important;
    margin-top: -5px;
  }

  .line-des1 {
    gap: 20px !important;
  }

  .contact_form p {
    font-size: 15px;
  }

  .text-64 {
    font-size: 40px;
    line-height: 40px !important;
  }

  .text-32 {
    font-size: 20px;
    line-height: 20px;
  }

  button {
    font-size: 15px !important;
  }

  .text-20 {
    font-size: 15px;
    line-height: 19px !important;
    margin-bottom: 0px;
  }

  .text-48 {
    font-size: 30px;
    line-height: 30px !important;
  }

  .text-30 {
    font-size: 20px;
    line-height: 20px;
  }

  .slider_left .slick-list {
    width: 1400px !important;
  }

  .Find_Out_More {
    min-height: 403px;
  }

  .occasion_for .hiddened {
    height: 183px;
  }

  .occasion_for1 {
    height: 385px;
  }

  .last_rentals h2 {
    font-size: 19px;
    line-height: 23px;
    width: 94px;
    margin: auto;
    margin-bottom: 5px;
    word-break: break-word;
  }

  .occasion_for1 .book_button {
    width: 83px !important;
    padding: 5px 0px;
  }

  .Massage ul li {
    margin-bottom: 10px;
  }

  .Depending_img {
    min-height: 45vh !important;
  }
}

.min_header .selected {
  width: 55px;
}

.descover_3 .EVENTS_L {
  padding-left: 11px;
}

.descover_3 .left_EVENTS_L .GROUPS_ev {
  margin-left: -60px;
}

.descover_3 .left_EVENTS_L {
  padding-left: 0px;
  padding-right: 16px;

}

.descover_3 .DeerImg {
  width: 50px;
  object-fit: contain;
}

@media (max-width:767px) {
  .icon_p img {
    width: 15px;
  }

  .Depending_img {
    background-size: cover !important;
  }

  .contact_form p {
    font-size: 11px;
  }

  .descover_3 .left_EVENTS_L {
    padding-left: 0px;
    padding-right: 0px;
  }

  .descover_3 .GROUPS_ev {
    right: -14px !important;
    margin-top: -18px;
    margin-right: 0px;
  }

  .descover_3 .DeerImg {
    width: 27px;
    height: 27px;
    object-fit: contain;
  }

  .descover_3 .left_EVENTS_L .GROUPS_ev {
    margin-left: 0px;
  }

  .descover_3 .EVENTS_L {
    border: 1.5px solid #573B0F;
    outline: 1.5px solid #573B0F;
    outline-offset: -5px;
    padding-left: 0px;
    padding-bottom: 14px;
    margin-top: -37px;
    right: 14px !important;
  }

  .dezcover_s {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }

  .min_header .selected {
    width: 44px;
  }

  .button_p {
    width: 130px;
  }

  .occasion_for1 {
    height: 173px;
  }

  .occasion_for .hiddened {
    height: 77px;

  }

  .Find_Out_More {
    min-height: 220px;
  }

  .text-64 {
    font-size: 20px;
    line-height: 20px !important;
  }

  .text-32 {
    font-size: 19px;
    line-height: 18px;
  }

  button {
    font-size: 11px !important;
  }

  .text-20 {
    font-size: 12px;
    line-height: 13px !important;
    margin-bottom: 0px;
  }

  .text-48 {
    font-size: 21px;
    line-height: 22px !important;
  }

  .text-30 {
    font-size: 15px;
    line-height: 15px !important;
  }

  .text-32 {
    font-size: 15px;
    line-height: 15px !important;
  }

  .book_button {
    padding: 3px 2px;
  }

  .text-21 {
    font-size: 10px !important;
    line-height: 14px;
  }

  .gap_0 {
    gap: 0px !important;
  }

  .h-490 {
    min-height: 200px !important;
  }

  .mt_0 {
    margin-top: 10px !important;
  }

  .mt_5 {
    margin-top: 10px !important;
  }

  .slider_left .slick-list {
    width: 100%;
  }

  .text-ellips {
    text-overflow: ellipsis;

    height: fit-content;
  }

  .button_bok {
    font-size: 9px !important;
  }

  .slick-prev,
  .slick-next {
    transform: scale(0.7);
  }

  .slick-next {
    margin-top: -50px;
    margin-right: -4px;
  }

  .slick-prev {
    margin-left: -4px;
  }

  .GROUPS_EVENTS .slick-slider .slick-track,
  .slick-slider .slick-list {
    transform: translate3d(0, 0, 0) !important;
    width: 100% !important;
    padding: 5px;
  }

  .GROUPS_EVENTS .slick-track {
    display: grid !important;
  }

  .GROUPS_EVENTS .slick-slide {
    margin-right: 0px !important;
    width: 100% !important;

  }

  .GROUPS_EVENTS .slick-slide {
    display: none;
  }

  .GROUPS_EVENTS .slick-slide:nth-child(1),
  .GROUPS_EVENTS .slick-slide:nth-child(2),
  .GROUPS_EVENTS .slick-slide:nth-child(3) {
    display: block;
  }

  .rabbitslider {
    margin: 2px 0px;
    margin-right: 0px;
    margin-bottom: 10px;
  }

  .Directly .carousel-caption {
    min-height: fit-content;
  }

  .carousel-indicators [data-bs-target] {
    width: 10px !important;
    height: 1px !important;
    margin-top: 10px;
  }



  .carousel-indicators {
    bottom: -13px !important;
  }

  .Massage {
    width: 100% !important;
  }

  .Massage ul li {
    margin-bottom: 10px;
    margin-left: -30px;
  }

  .attraction_Type {
    height: 300px;
  }

  input::placeholder {
    font-size: 12px !important;
  }

  textarea::placeholder {
    font-size: 12px !important;
  }

  input {
    height: 30px;
  }

  .last_rentals h2 {
    font-size: 10px;
    line-height: 10px;
    width: fit-content;
    margin-bottom: -4px;
  }

  .occasion_for1 .book_button {
    width: 48px !important;
  }

  .selection_select {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .gup_ev .EVENTS_L {
    margin-left: -10px;
    padding-bottom: 12px;
  }

  .gup_ev .GROUPS_ev {
    right: -11px;
  }

  .gup_ev .left_EVENTS_L .GROUPS_ev {
    left: 14px;
    margin-left: 0px;
  }

  .gup_ev .left_EVENTS_L {
    margin-left: 0px;
    padding-right: 0px;
  }

  .phoneNumber button {
    width: fit-content;
  }

  .Send {
    padding: 35px;
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .subject {
    width: 10px;
  }

  .Groups_reservation input {
    padding-left: 9px;
  }

  .Groups_reservation textarea {
    padding-left: 9px;
    height: 68px;
    padding-top: 8px;
  }

  .Groups_reservation textarea::placeholder {
    top: -8px;
    position: relative;
  }

  .p_button {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .Depending_img {
    min-height: 30vh !important;

  }

  .slider_imgs {
    min-height: 45vh !important;
  }

  .line-des1 {
    align-items: flex-start !important;
    gap: 9px !important;
  }

  .line-des {
    margin-top: 6px !important;
    width: 22px !important;
  }

  .celebrate {
    width: 37% !important;
    margin-top: 31px !important;
  }
  .languagaes_fr .options {
    width: 60px;
}
.languagaes_fr .options li {
  cursor: pointer;
  padding: 2px 7px !important;
}
}

@media (max-width:540px) {
  .celebrate {
    width: 100% !important;
  }
}

@media (max-width:420px) {
  .colleagues {
    width: 40% !important;
  }

  .About_d .Depending_img {
    min-height: 15vh !important;
  }

  .colleagues1 {
    width: 75% !important;
  }
}

@media (max-width:386px) {
  .slider_left .slick-list {
    width: 390px !important;
  }

}

@media (max-width:374px) {
  .occasion_ {
    width: 100%;
  }

  .occasion_for1 {
    height: 280px;
  }

}
input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: black !important;
  color: fieldtext !important;
}

.header_p {
  position: fixed;
  width: 100%;
  z-index: 9999999;
  top: 0px;
  transition: 2s;
  transform: translateY(0%);
  animation-name: example;
  animation-duration: 1s;

}

.bg-treecolor {
  transition: transform 0.2s ease;
}

.header_i {
  transform: translateY(0%);
  position: relative;
  z-index: 99999;
}

@keyframes example {
  from {
    transform: translateY(-100%);
  }

  to {
    background-color: yellow;
  }
}

.family_fillte .Depending_img::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.3);
}

.carousel-item::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0, 0.2);
}

.family_fillte .Depending_img .container {
  z-index: 99;
}

.family_fillte .text-center {
  z-index: 99;
}

.faq_fg .active .pule {
  display: none;
}

.faq_fg .munes {
  display: none;
}

.faq_fg .active .munes {
  display: block;
}

.icon_p {
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-right: 70px;


}

.faq_fg {
  position: relative;
}

.faq_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.faq_fg .active {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;

}

.faq_menu {
  display: block;
}

.faq_fg .active .faq_menu {
  display: block !important;
}

.copororat_img .Depending_img {
  background-size: 100% 100% !important;
  text-transform: uppercase;
}

.copororat_img .Depending_img::before {
  background-color: #00000080 !important;
}

@media (max-width:1025px) {
  .copororat_img .Depending_img {
    background-size: cover !important;
  }
}

.Learn_more {
  text-transform: uppercase !important;
}

.darkdata .bg-no-repeat h1 {
  max-width: 1400px;
}

.family_fillte .bg-no-repeat h1 {
  padding-left: 20px;
  padding-right: 20px;
}

@media (max-width:1024px) {
  .descover_3 .DeerImg {
    object-fit: contain;
    width: fit-content !important;
    height: 30px !important;
  }

  .unocon_r {
    object-fit: contain;
    width: fit-content !important;
    height: 30px !important;
  }
}

@media (max-width:767px) {
  .descover_3 .DeerImg {
    height: 20px !important;
  }
}

@media (max-width:530px) {
  .unocon_r {
    object-fit: contain;
    width: fit-content !important;
    height: 20px !important;
  }

  .leftIcone img {
    max-height: 30px !important;
  }

  .rebit_ar {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .leftIcone .capitalize {
    margin-left: 0px !important;
  }

  .rebit_ar .leftIcone:nth-child(2) img {
    max-height: 24px !important;

  }

}

.all_groups .animation_img::before {
  position: absolute;
  background-color: rgb(0, 0, 0, 0.3);
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  content: "";
  z-index: 9;
}

.all_groups .relative {
  z-index: 999;
}

.Corporateslide_s .slick-list {
  display: flex;
}

.Corporateslide_s .slick-slide>div {
  height: 100%;
}
.Amenities_h .text-white {
  color: black !important;
}

.gooddes {
  width: 60px;
  height: 2px;
  background-color: white;
  display: block;
  margin-top: -10px;
}

.descover_3 .EVENTS_L {
  transform: scale(0.9);

}

@media (max-width:767px) {
  .descover_3 .EVENTS_L {
    transform: scale(1);

  }

  .gooddes {
    width: 20px;
    height: 2px;
    background-color: white;
    display: block;
    margin-top: 5px;
  }
}

.Depending_img h3 br {
  display: none;
}

.texzt_ex {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  padding-right: 15%;
}
@media (max-width:600px) {
  .team_will{
    width: 100%;
    height: 100%;
  }
}




.newsletter_main_container {
  width: 100%;
}

.newsletter_main_container input {
  width: 100%;
  background-color: transparent;
  border: none;
  border: 0.1px solid rgba(255, 255, 255, 0.575);
  border-radius: 10px;
  color: black;
  padding: 8px 8px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 18px;
  text-align: left;
}

.newsletter_main_container input::placeholder {
  color: #7e7e7e;
}

.newsletter_main_container input:focus {
  outline: none;
}

.newsletter_main_container textarea:focus {
  outline: none;
}

.newsletter_main_container span {
  color: red;
}
@font-face {
  font-family: 'Akagi-Pro-bold';
  font-style: normal;
  font-weight: 400;
  src: local('Myriad Pro Condensed'), url('./App/Assets/font/Akagi-Pro-W00-Bold-1.ttf') format('woff');
}



.white_class {
  color: #d18e06 !important;
  background-color: white !important;
  border: none;
  outline: none;
  font-family:'Akagi-Pro-bold';
}


.white_class:hover {
  background-color: #d18e06 !important;
  color: white !important;
  border: none;
  outline: none;
  font-family:'Akagi-Pro-bold';
}

.white_class:hover .custom-dropdown2 {
  color: white !important;
}

.white_class:hover .downArrowIcon {
  filter: contrast(0.5);
}



.Question_grid {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 30px;
  margin-top: 30px;
}


.Success {
  position: fixed;
  top: 0px;
  height: 100%;
  background-color: #00000069;
  width: 100%;
  left: 0px;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}


.Success_center {
  background-color: #BD8833;
  
  width: 450px;
  border-radius: 10px;
  padding: 20px;
  color: white;
  font-size: 22px;
  font-weight: 700;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}


.custom-btn {
  color: #fff;
  border-radius: 5px;
  padding: 10px 35px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, 0.5),
    7px 7px 20px 0px rgba(0, 0, 0, 0.1), 4px 4px 5px 0px rgba(0, 0, 0, 0.1);
  outline: none;
  font-family: 'Akagi-Pro-bold';
  font-weight: 700;
  border-radius: 100px;
  overflow: hidden;
}

.btn-14 {
  background: #e7d5b1;
  border: none;
  z-index: 1;
  color: #000;
}


.btn-14:hover {
  color: #BD8833;
  background-color: white;
}

.btn-14:hover:after {
  top: auto;
  bottom: 0;
  height: 100%;
}

.btn-14:active {
  top: 2px;
}


.underline_new {
  text-decoration: underline !important;
}

.Terms_and p {
  --tw-text-opacity: 1;
  color: rgb(214 214 214 / 1);
  color: rgb(214 214 214 / var(--tw-text-opacity));
  font-size: 1.25rem;
  line-height: 2.1rem;
  margin: 34px 0px;
}

.Terms_and .container{
  max-width: 1100px !important;
}
