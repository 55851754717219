.switch-container-summer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px; /* Proportional width for a medium design */
  height: 45px; /* Balanced height */
  bottom: 3.2vh; /* Adjusted positioning for visual balance */
  left: 15px; /* Added spacing from the right edge */
  background: linear-gradient(135deg, #BD8833, #A6752B);
  border-radius: 25px; /* Fully rounded edges */
  cursor: pointer;
  position: fixed;
  transition: background 0.3s ease, box-shadow 0.3s ease;
  z-index: 9999;
  box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.3);
}

.switch-container-summer:hover {
  background: linear-gradient(135deg, #BD8833, #D1974C);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.4);
}

.switch-summer {
  width: 40px; /* Medium-sized toggle button */
  height: 40px; /* Slightly smaller for better fit */
  border-radius: 20px; /* Matches container rounding */
  position: absolute;
  top: 2.5px; /* Centered vertically */
  left: 2.5px; /* Adjusted for perfect alignment */
  background: linear-gradient(135deg, #FFF5E1, #FFE4B3);
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
}

.switch-summer.on {
  transform: translateX(46px); /* Adjusted for smooth transition within container */
  background: linear-gradient(135deg, #FFE9C5, #FFD590);
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.3), 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.summer-icon {
  pointer-events: none; /* Prevents interaction with the icons */
  width: 28px; /* Proportional icon size */
  height: 28px; /* Matches the toggle size */
  filter: drop-shadow(0px 3px 4px rgba(0, 0, 0, 0.25));
}

.summer-icon:hover {
  filter: drop-shadow(0px 5px 8px rgba(0, 0, 0, 0.3));
}
