.min_ChaletsByType .chalet {
  margin: 40px 0;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.min_ChaletsByType .chalet-title {
  color: #333;
}

.min_ChaletsByType .chalet-location {
  color: #777;
}

.min_ChaletsByType .chalet-content {
  display: flex;
  gap: 50px;
  margin-top: 50px;
  margin-bottom: 50px;
}
.min_ChaletsByType .chalet-info {
  width: 60%;
}

.min_ChaletsByType .book-button {
  padding: 10px 30px !important;
  margin: 20px 0px !important;
}

.min_ChaletsByType .image-gallery {
  display: flex;
  gap: 50px;
}

.min_ChaletsByType .main-image {
  width: 65%;
  height: 540px;
  border-radius: 20px;
}
.min_ChaletsByType .main-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.min_ChaletsByType .thumbnail-images {
  display: grid;
  gap: 50px;
  width: 40%;
}

.min_ChaletsByType .thumbnail {
  width: 100%;
  object-fit: cover;
  border-radius: 20px;
}

.min_ChaletsByType .property-features {
  width: 36%;
  border: 1px solid #bc8733;
  padding: 20px;
  border-radius: 8px;
  background-color: transparent;
}

.min_ChaletsByType .property-features h4 {
  margin-bottom: 10px;
}

.min_ChaletsByType .property-features ul {
  list-style-type: unset;
  padding-left: 20px;
}

.min_ChaletsByType .property-features li {
  margin: 5px 0;
}
.min_ChaletsByType .bathrooms::before {
  content: "-";
  padding-right: 12px;
  margin-left: -20px;
}

.min_ChaletsByType .bathrooms {
  margin: 7px 0px;
}
.swiper-container {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 100%;
}

.swiper-wrapper {
  display: flex;
  transition: transform 0.3s ease-in-out;
  height: 100%;
}

.swiper-slide {
  min-width: 100%;
  transition: opacity 0.3s ease-in-out;
  height: 100%;
  opacity: 0.5;
}
.swiper-slide.active {
  opacity: 1; /* Fully visible for the active slide */
  z-index: 1; /* Bring active slide to the front */
}
.swiper-slide img {
  width: 100%;
  display: block;
  height: 100%;
}

.swiper-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  background-color: rgba(0, 0, 0, 0.6);
  /* background-color: #BC8733; */
  color: white;
  border: none;
  font-size: 20px;
  cursor: pointer;
  /* padding: 10px; */
  border-radius: 100%;
  z-index: 10;
}

.swiper-button.prev {
  left: 10px;
}

.swiper-button.next {
  right: 10px;
}

.navigation_Scroll_hide::-webkit-scrollbar {
  display: none;
}

.navigation::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for Firefox */
.navigation {
  scrollbar-width: none; /* For Firefox */
}

/* Ensure content is still scrollable */
.navigation {
  overflow-y: scroll;
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

.scroll-section {
  position: relative;
  /* min-height: 100vh;  */
}
.navigation {
  position: fixed;
  left: 0px;
  top: 0px;
  background-color: transparent;
  z-index: 9999;
  height: 100%;
  align-self: center;
  align-items: center;
  flex-grow: 1;
  display: flex;
  overflow: auto;
}

.navigation .nav-button {
  display: flex;
  background-color: #bd8833;
  z-index: 99999;
  display: block;
  border-radius: 0px 0px 0px 0px;
  padding: 8px;
  transition: 0.5s;
  color: white;
  font-size: 14px;
  font-weight: 700;
  opacity: 0.5;
  min-width: 50px;
}
.left_menu_Chalet_Type {
  background-color: #bd8833;
  height: auto;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 1.35) 0px 5px 10px;
}
.chalet__title {
  color: #fff;
  font-size: 15px;
  cursor: pointer;
}

.active-button {
  background-color: #bd8833 !important;
  opacity: 1 !important;
  font-size: 17px !important;
  padding: 10px !important;
  border-radius: 0% 0px 0px 0px !important;
  min-width: 90px !important;
}

.navigation .nav-button:hover {
  background-color: #bd8833 !important;
  opacity: 1 !important;
  font-size: 17px !important;
  padding: 10px !important;
  border-radius: 0% 0px 0px 0px !important;
  min-width: 90px !important;
}

.chalets-feature-item {
  display: flex;
  align-items: start;
  position: relative;
  gap: 10px;
  margin-top: 5px;

}

.circle-container {
  width: 16px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.black-circle {
  width: 6px !important;
  height: 6px !important;
  background-color: black;
  border-radius: 6px;
  margin-top: 10.5px;
}

.chalets-feature-text {
  display: block;
}

.people_icon_style {
  width: 16px;
  height: 16px;
  object-fit: cover;
  display: block;
  margin-top: 5px;
}

@media (max-width: 767px) {
  .active-button {
    font-size: 12px !important;
    padding: 6px !important;
    min-width:55px !important;
  }
  .navigation .nav-button:hover {
    font-size: 12px !important;
    padding: 6px !important;
    min-width:55px !important;
  }

  .navigation .nav-button {
    padding: 6px;
    font-size: 12px;
    font-weight: 500;
    min-width:40px;
  }
  .swiper-button {
    height: 20px;
    width: 20px;
  }
  .image-gallery {
    flex-wrap: wrap;
  }
  .min_ChaletsByType .chalet-info {
    width: 100%;
  }
  .min_ChaletsByType .property-features {
    width: 100%;
  }
  .min_ChaletsByType .image-gallery {
    gap: 20px;
  }
  .min_ChaletsByType .thumbnail-images {
    gap: 20px;
  }
  .min_ChaletsByType .chalet-content {
    gap: 0px;
    margin-top: 24px;
    margin-bottom: 24px;
  }

  .min_ChaletsByType .thumbnail {
    border-radius: 9px;
    width: 100%;
  }
  .min_ChaletsByType .main-image {
    border-radius: 9px;
    width: 100%;
    height: 220px;
  }

  .circle-container {
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .black-circle {
    width: 4px !important;
    height: 4px !important;
    background-color: black;
    border-radius: 4px;
    margin-top: 3.5px;
  }

  .people_icon_style {
    width: 12px;
    height: 12px;
    object-fit: cover;
    margin-top: 1px;
  }
}
