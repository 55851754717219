@font-face {
  font-family: 'Alfarn';
  font-style: normal;
  font-weight: 400;
  src: local('Myriad Pro Condensed'), url('../../../Assets/font/Alfarn.otf') format('woff');
}



.Alfarn {
  font-family: 'Alfarn';
}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 54%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: black;
  border: none;
  outline: none;
  background: transparent;
  z-index: 999999999;
}

.slick-next {
  right: 0px;
  z-index: 9999999999999999;
  background: rebeccapurple;
}

.rabbitslider {
  box-shadow: 0px 1px 14px 1px #00000026;
  background-color: #F3F3F3;
  margin: 10px 10px;
}

.slick-slide {
  margin-right: 30px !important;

}

.slick-slide:last-child {
  margin-right: 0 !important;
}

.slick-track {
  display: flex !important;
}

.slick-prev {
  left: 50% !important;
  z-index: 999999 !important;
  top: 111% !important;
  margin-left: -58px !important;
}

.slick-next {
  right: 50% !important;
  z-index: 999999 !important;
  top: 111% !important;
  margin-right: -58px !important;
}

.slick-prev:before,
.slick-next:before {
  color: red !important;
  content: "\f104" !important;
  font-family: FontAwesome;
}

.slick-prev,
.slick-next {
  background-color: transparent !important;
  border-radius: 100% !important;
  background-image: url(../../../Assets/Icons/arror1.png) !important;
  background-size: 50px 50px !important;
  height: 50px !important;
  width: 50px !important;
  rotate: 180deg;
  margin-top: -50px;
  margin-right: 10px;
  margin-left: 10px;
}

.slick-next {
  background-image: url(../../../Assets/Icons/arror1.png) !important;
  background-size: 50px 50px !important;
  rotate: 0deg;
  margin-top: -0px;
}

@media (max-width:1420px) {
  .gapy {
    gap: 20px;

  }

}

@media (max-width:1279px) {
  .gapy {
    gap: 1.75rem;
  }

}

.hiddened:hover .hiddenead {
  display: flex;
  z-index: 2;
}

.hiddened:hover::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  top: 0;
  left: 0;

}
.GROUPS_EVENTS .slick-slide {
  margin-right: 10px !important;
}

.GROUPS_EVENTS .slick-prev,
.GROUPS_EVENTS .slick-next {
  display: none !important;
}

.slick-prev::before,
.slick-next::before {
  color: transparent !important;
  content: none !important;
}

.mosum::first-letter {
  font-size: 200% !important;
}

.slick-prev:hover,
.slick-next:hover {
  filter: brightness(8) invert(1);
}
.textDecoration{
 
  background-color: rgb(189, 136, 51)!important;
  color: white!important;
  padding: 10px 20px;
}